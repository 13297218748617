import React from "react"
import { Box, Text, Flex } from "@sqymagma/elements"
import Block from "../components/block"
import Tick from "../components/tick"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import styled from "styled-components"

import data from "../data/m17"

const Icn = styled(IcnSystem)`
  height: 32px;
  padding-right: 24px;
  ${p => p.theme.mq.s} {
    height: 48px;
    padding-right: 32px;
  }
  ${p => p.theme.mq.m} {
    padding: 0px;
    height: 64px;
  }
  ${p => p.theme.mq.l} {
    height: 64px;
  }
  ${p => p.theme.mq.xl} {
    height: 80px;
  }
`

const Title = styled(Text)`
  text-transform: uppercase;
`

const Card = ({ cardID, cdata }) => {
  return (
    <Flex
      textAlign={["left", "left", "center"]}
      px={["0px", "0px", "spc_2", "spc1", "spc3"]}
      py={["spc", "spc", "0"]}
      flexDirection={["row", "row", "column"]}
    >
      <Box pb={["spc_2", "spc_2", "spc1", "spc1", "spc2"]}>
        <Icn name={cdata.icon} fill="text03" />
      </Box>
      <Box textAlign={["left", "left", "center"]}>
        <Box
          pb={["spc_2", "spc_2", "spc_1", "spc_1", "spc1"]}
          maxWidth="120px"
          m={["0", "0", "auto"]}
        >
          <Title textStyle="h6">{cdata.title}</Title>
        </Box>
        <Box>
          <Text textStyle="p1">{cdata.text}</Text>
        </Box>
      </Box>
    </Flex>
  )
}
const Punto = ({ pdata, puntoID }) => {
  const listCards = pdata.cards.map((card, i) => <Card cardID={i + 1} cdata={card} />)
  return (
    <Flex flexDirection={["column", "row"]} mb={["spc3", "spc3", "spc3", "spc3", "spc4"]}>
      <Box
        width={["auto", "136px", "208px", "256px", "336px"]}
        mr={["spc1", "spc2"]}
        mb={["spc3"]}
        mt={["0", "0", "spc", "spc", "spc2"]}
      >
        <Box>
          <Text textStyle="h1" color="brand01">
            0{puntoID}.
          </Text>
        </Box>
        <Box>
          <Text textStyle="h4">{pdata.title}</Text>
          <Tick />
          <Text textStyle="p1">{pdata.text}</Text>
        </Box>
      </Box>

      <Flex
        flexDirection={["column", "column", "row"]}
        bg="bg01"
        px={["spc1", "spc3", "spc2", "spc3", "spc4"]}
        py={["spc", "spc2", "spc6"]}
        width={["100%", "448px", "688px", "832px", "976px"]}
      >
        {listCards}
      </Flex>
    </Flex>
  )
}

const ListadoPuntos = () => {
  const listPuntos = data.puntos.map((punto, i) => <Punto puntoID={i + 1} pdata={punto} />)
  return (
    <Box py="spc6" textAlign="center" width="100%">
      <Block>
        <Box px={["0px", "spc4", "0px"]}>
          <Box
            mx={["0", "spc4", "spc8", "scp12", "scp16"]}
            mb={["spc3", "spc8", "spc6", "spc8", "spc12"]}
          >
            <Text textStyle="h3">{data.title}</Text>
            <Box pt="spc2" />
            <Text textStyle="p">{data.t2}</Text>
          </Box>
          <Flex textAlign="left" flexDirection="column">
            {listPuntos}
          </Flex>
        </Box>
      </Block>
    </Box>
  )
}

export default ListadoPuntos
