import React from "react"

import Layout from "../modules/layout"
import BigBanner from "../modules/m18_bigBanner"
import Habitabilidad from "../modules/m19_habitabilidad"
import InnerHero from "../modules/m14_InnerHero"
import PuntosComoInvertir from "../modules/m17_PuntosComoInvertir"

const ComoPage = () => {
  return (
    <Layout
      title="Reforma de locales comerciales en viviendas | Living Alternatives"
      desc="Explora con Living Alternatives los requisitos para convertir un local en vivienda. Conoce con nuestro equipo la normativa para convertir un local en vivienda."
      keywords="reforma de locales comerciales,
      reformas de locales comerciales,
      normativa para convertir un local en vivienda,
      requisitos para convertir un local en vivienda,
      requisitos para convertir local en vivienda en madrid"
      url="como-lo-hacemos"
    >
      <InnerHero
        bc="Cómo lo hacemos"
        title="¿Cómo lo hacemos?"
        url="como-lo-hacemos"
        tagidbotshare="TM20-ComoHacemos-MóduloCompartir-BotonCompartir"
      />
      <PuntosComoInvertir />
      <BigBanner
        buttonlink="/convertir-local-en-vivienda-madrid-casos-practicos"
        cta="Ver casos prácticos"
        tagidbot="TM21-ComoHacemos-MóduloExito-BotonCasoExito"
      >
        Experiencias anteriores en el cambio de uso de locales comerciales a vivienda
      </BigBanner>
      <Habitabilidad></Habitabilidad>
    </Layout>
  )
}

export default ComoPage
