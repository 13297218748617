const data = {
  title: "Condiciones de habitabilidad",
  subtitle:
    "La nueva vivienda debe cumplir las condiciones de habitabilidad establecidas por la Normativa Urbanística vigente en el Ayuntamiento de cada distrito.",
  subtitle2:
    "En Living Alternatives garantizamos que el local comercial cumple todos los requisitos técnicos y legales necesarios para el nuevo uso:",
  cards: [
    {
      title: "Vivienda mínima",
      text:
        "Se considera vivienda mínima aquella que cuenta con estancia-comedor, cocina, dormitorio y aseo distribuidos en una superficie útil de al menos 38m2, no incluyéndose en este cómputo el espacio destinado a terraza, balcón, etc. Si se tratase de un estudio, la superficie útil del local debe ser de al menos 25m2.",
      icon: "icnvivmin",
    },
    {
      title: "Dimensiones del espacio",
      text:
        "La nueva vivienda debe contar con una altura de 2,5 m en al menos el 75% de la superficie útil, pudiendo reducirse hasta 2,2 m el resto del espacio. La anchura de la fachada tiene que ser de 3 m, y dispondrá de una puerta de acceso de 2,03 x 8,25 m. La superficie útil del salón debe ser superior a 12m2.",
      icon: "icndim",
    },
    {
      title: "Nivel del suelo",
      text:
        "El suelo de la vivienda debe estar situado igual o por encima de la acera (no habrá piezas habitables en niveles inferiores), quedando descartados aquellos edificios que hayan sido construidos bajo rasante o sótanos. Si se trata de una calle en pendiente, se tomará como referencia el punto de la acera más elevado.",
      icon: "icnsuelo",
    },
    {
      title: "Cocina",
      text:
        "Al ser una pieza en la que se produce combustión o gases, dispondrá de chimenea para su evacuación, independientemente del hueco de luz y ventilación.",
      icon: "icncoc",
    },
    {
      title: "Iluminación",
      text:
        "Los huecos de iluminación natural de la vivienda deberán tener una superficie no inferior al 12 por ciento de la superficie útil de la pieza habitable.",
      icon: "icnilumina",
    },
    {
      title: "Ventilación natural",
      text:
        "Cada una de las piezas habitables dispondrá de una superficie practicable a efectos de ventilación.",
      icon: "icnventila",
    },
    {
      title: "Inscripción circulo de diámetro",
      text:
        "Será posible inscribir un círculo de diámetro igual o mayor de 270 centímetros, tangente al paramento en el que se sitúa el hueco de luz y ventilación.",
      icon: "icncirc",
    },
    {
      title: "Código técnico de la edificación",
      text:
        "Hay que cumplir el Código Técnico de la Edificación, especialmente en materias de accesibilidad, salubridad y seguridad frente a incendios.",
      icon: "icncodigo",
    },
    {
      title: "Grado de protección",
      text:
        "El grado de protección del edificio nos va a marcar el tipo de reforma que va a poder realizar. Si el grado de protección es parcial, únicamente podrá ser modificado en el interior.",
      icon: "icnprot",
    },
  ],
}

export default data
