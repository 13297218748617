import React from "react"
import { Box, Columns, MaxWidth, Text } from "@sqymagma/elements"
import Block from "../components/block"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import styled from "styled-components"

import data from "../data/m19"

const Title = styled(Text)`
  text-transform: uppercase;
`

const Card = ({ cdata }) => {
  return (
    <Box textAlign="left" px={["0px", "0px", "spc_2", "spc1", "spc3"]} py={["spc1"]}>
      <IcnSystem name={cdata.icon} height="48px" fill="brand03" />
      <Box py={"spc1"}>
        <Title textStyle="h6">{cdata.title}</Title>
      </Box>
      <Box>
        <Text textStyle="p">{cdata.text}</Text>
      </Box>
    </Box>
  )
}

const Habitabilidad = () => {
  const listCards = data.cards.map(card => <Card cdata={card} />)
  return (
    <MaxWidth py="spc6" textAlign="center">
      <Block>
        <Box mx={[0, 64, 120, 192, 256]}>
          <Text textStyle="h3">{data.title}</Text>
          <Box pt="spc3" />
          <Text textStyle="subtitle" color="text03">
            {data.subtitle}{" "}
          </Text>
          <Box>
            <Text textStyle="subtitle" color="text03">
              {data.subtitle2}
            </Text>
          </Box>
          <Box pt={["spc3", "spc4", "spc6", "spc6", "spc8"]} />
        </Box>
        <Columns cols={[1, 3]} textAlign="center">
          {listCards}
        </Columns>
      </Block>
    </MaxWidth>
  )
}

export default Habitabilidad
