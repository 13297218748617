const m17 = {
  title: "Nos encargamos de todo",
  t2:
    "Nuestro objetivo es simplificar la gestión de la inversión para que nuestros clientes sólo tengan que preocuparse del rendimiento económico de las propiedades resultantes. Por ello, ofrecemos un servicio ágil e integral, que nos permite reducir tiempos, costes y trámites.",
  puntos: [
    {
      title: "Búsqueda",
      text:
        "Seleccionamos y estudiamos las mejores oportunidades de inversión optimizando la viabilidad técnica (para que sea susceptible de cambio de uso) y la rentabilidad futura del alquiler.",
      cards: [
        {
          title: "Búsqueda del local",
          text:
            "Seleccionamos locales aptos técnica y jurídicamente para obtener el cambio de uso a vivienda.",
          icon: "icnbuscar",
        },

        {
          title: "Viabilidad de la inversión",
          text:
            "Estudio exhaustivo de la viabilidad del cambio de uso y la rentabilidad económica. Due Diligence legal y técnica.",
          icon: "icnvia",
        },
        {
          title: "Proyecto técnico",
          text:
            "Redacción del proyecto técnico para cumplir con los aspectos de accesibilidad y condiciones de habitabilidad.",
          icon: "icnvalora",
        },
      ],
    },
    {
      title: "Adquisición",
      text:
        "Preparación e Inscripción de la compra del local así como su previa revisión legal y técnica.",
      cards: [
        {
          title: "Adquisición del inmueble",
          text:
            "Preparación y redacción de todos los contratos vinculados a la compra de los Inmueble.",
          icon: "icnnorma",
        },
        {
          title: "Inscripción y Registro",
          text:
            "Firma en Notaría, inscripción en el Registro de la Propiedad de Madrid y pago de impuestos.",
          icon: "icnproy",
        },
        {
          title: "Propuesta de Reforma y Cambio de Uso",
          text:
            "Redacción y presentación del proyecto en la Junta Municipal del distrito correspondiente.",
          icon: "icnlicencia",
        },
      ],
    },
    {
      title: "Reforma",
      text:
        "Reformamos y decoramos con altas memorias de calidades con el objetivo de posicionar las viviendas en el segmento alto de alquileres en dichos barrios.",
      cards: [
        {
          title: "Reforma de la vivienda",
          text:
            "Con una memoria de calidades y materiales testada en más de 200 reformas anteriores",
          icon: "icnreforma",
        },
        {
          title: "Decoración integral",
          text: "Diseño y decoración de las viviendas con productos de alta calidad.",
          icon: "icndecoracion",
        },
        {
          title: "Licencia de la vivienda",
          text:
            "Solicitud y obtención de la Licencia de Primera Ocupación (L.P.O.) e inscripción de la misma en el Registro de la Propiedad",
          icon: "icncalidad",
        },
      ],
    },
    {
      title: "Gestión de la vivienda",
      text:
        "Gestionamos todos los aspectos relacionados con los alquileres y la gestión de los inquilinos y el cobro de la rentas.",
      cards: [
        {
          title: "alquiler de la vivienda",
          text: "Búsqueda del inquilino y negociación del contrato de alquiler.",
          icon: "icnalquiler",
        },
        {
          title: "gestión del inquilino",
          text: "Gestión del alquiler y mantenimiento de la vivienda durante la vida del contrato.",
          icon: "icninquilino",
        },
        {
          title: "Seguro de alquiler",
          text:
            "Ofrecemos adicionalmente un seguro de alquiler y análisis de viabilidad económica del inquilino.",
          icon: "icnlicencia2",
        },
      ],
    },
  ],
}

export default m17
